import {useContext, useEffect, useState} from 'react';
import {Button, Col, ConfigProvider, InputNumber, List, Row, Select, Spin} from 'antd';

import {axiosCall, MAX_YEAR, MESSAGE_TYPE_ERROR, MESSAGE_TYPE_SUCCESS, MIN_YEAR, MONTHS_OBJECTS, showToast} from '../utilities';
import Cost from "./Cost";
import {GlobalDataContext} from "./App";

const {Option} = Select;

const Costs = () => {
    const globalDataContext = useContext(GlobalDataContext);

    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState(MONTHS_OBJECTS[new Date().getMonth()]);
    const [loadingData, setLoadingData] = useState(false);
    const [costs, setCosts] = useState([]);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMonth, selectedYear]);

    const getData = async () => {
        try {
            setLoadingData(true);
            setCosts([]);

            const response = await axiosCall('GET', `/costs/${selectedYear}/${selectedMonth.id}/${globalDataContext.loggedUserId}`, null, globalDataContext.logout);

            setCosts(response.data);
        } catch (e) {
        }

        setLoadingData(false);
    };

    const daysInMonth = new Date(selectedYear, (selectedMonth.id) % 12, 0).getDate();

    const saveCost = async (day, commissionId, subcommissionId, amount, description) => {
        try {
            setLoadingData(true);

            const costObj = {
                date: parseInt(`${selectedYear}${selectedMonth.id.toString().padStart(2, '0')}${day.toString().padStart(2, '0')}`, 10),
                commissionId: commissionId,
                subcommissionId: subcommissionId,
                amount: amount,
                description: description
            };

            await axiosCall('POST', '/costs', costObj, globalDataContext.logout);

            getData();
            showToast('Voce di spesa salvata', MESSAGE_TYPE_SUCCESS, null);
        } catch (e) {
            showToast('Errore salvataggio voce di spesa', MESSAGE_TYPE_ERROR, e);
        } finally {
            setLoadingData(false);
        }
    };

    const deleteCost = async (id) => {
        if (id) {
            try {
                setLoadingData(true);

                await axiosCall('DELETE', `/costs/${id}`, null, globalDataContext.logout);
            } catch (e) {
                showToast('Errore cancellazione voce di spesa', MESSAGE_TYPE_ERROR, e);
            } finally {
                setLoadingData(false);
            }
        }

        showToast('Voce di spesa cancellata', MESSAGE_TYPE_SUCCESS, null);
        getData();
    };

    const EmptyTable = () => (
        <div style={{ textAlign: 'center' }}>
            <p>Non esistono voci di spesa per il mese e l'anno selezionati</p>
        </div>
    );

    return (
        <div style={{ padding: '0 20px 20px 20px' }}>
            <Spin spinning={loadingData} size="large">
                <Row gutter={8} style={{ marginBottom: 20 }}>
                    <Col xs={8} sm={4} md={3} lg={2}>
                        <InputNumber
                            style={{ width: '100%' }}
                            min={MIN_YEAR}
                            max={MAX_YEAR}
                            defaultValue={MAX_YEAR}
                            type={'number'}
                            onChange={setSelectedYear}
                        />
                    </Col>

                    <Col xs={16} sm={6} md={4} lg={3}>
                        <Select
                            style={{ width: '100%' }}
                            listHeight={384}
                            defaultValue={MONTHS_OBJECTS[new Date().getMonth()].name}
                            onChange={(value, option) => setSelectedMonth(MONTHS_OBJECTS[option.idx])}
                        >
                            {
                                MONTHS_OBJECTS.map((month, idx) => <Option key={'m' + idx} value={month.name} idx={idx}>{month.name}</Option>)
                            }
                        </Select>
                    </Col>
                </Row>

                <Row style={{ marginBottom: 20 }}>
                    <Button
                        type='primary'
                        disabled={costs.filter(d => d.id === 0).length > 0}
                        onClick={() => setCosts(costs.concat({id: 0}))}
                    >
                        Aggiungi voce di spesa
                    </Button>
                </Row>

                <Row style={{ width: '100%', fontWeight: 'bold' }}>
                    <Col span={2}>Giorno</Col>
                    <Col span={5}>Commessa</Col>
                    <Col span={6}>Sottocommessa</Col>
                    <Col span={3}>Ammontare</Col>
                    <Col span={6}>Descrizione</Col>
                </Row>

                <Row style={{ marginBottom: 20, width: '100%' }}>
                    <ConfigProvider renderEmpty={EmptyTable}>
                    <List
                        style={{ width: '100%' }}
                        dataSource={costs}
                        renderItem={item => <Cost
                                                daysInMonth={daysInMonth}
                                                item={item.id === 0 ? null : item}
                                                onSaveCost={saveCost}
                                                onDeleteCost={deleteCost}
                                            />
                                    }
                    />
                    </ConfigProvider>
                </Row>
            </Spin>
        </div>
    );
};

export default Costs;