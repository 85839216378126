import {Button, Col, Input, InputNumber, List, Row, Select} from "antd";
import {useContext, useState} from "react";
import {DeleteOutlined, SaveOutlined} from "@ant-design/icons";
import {getItem} from "../utilities";
import {GlobalDataContext} from "./App";

const {Option} = Select;
const { TextArea } = Input;

const Cost = ({
    daysInMonth,
    item,
    onSaveCost = null,
    onDeleteCost = null
}) => {
    const globalDataContext = useContext(GlobalDataContext);

    const [day, setDay] = useState(!!item ? parseInt(item.date % 100, 10) : new Date().getDate());
    const [commission, setCommission] = useState(!!item ? getItem(globalDataContext.allCommissions, item.commissionId) : null);
    const [subcommission, setSubcommission] = useState(!!item ? getItem(globalDataContext.allSubcommissions, item.subcommissionId) : null);
    const [amount, setAmount] = useState(!!item ? item.amount : 0);
    const [description, setDescription] = useState(!!item ? item.description : '');

    const renderDaysInMonth = () => {
        const days = [];

        for (let i = 1; i < daysInMonth + 1; i++) {
            days.push(<Option key={'d' + i} value={i}>{i}</Option>);
        }

        return days;
    };

    const onSelectCommission = (commission) => {
        setCommission(commission);
        setSubcommission(null);
    };

    const subcommsOfCurrentComm = globalDataContext.allSubcommissions.filter(subcomm => subcomm.commissionId === commission?.id);

    return (
        <List.Item>
            <Row gutter={8} style={{ width: '100%' }}>

                <Col span={2}>
                    <Select
                        style={{ width: '100%' }}
                        open={!!item ? false : undefined}
                        onChange={(value, option) => setDay(value)}
                        value={day}
                    >
                        {
                            !item && renderDaysInMonth()
                        }
                    </Select>
                </Col>

                <Col span={5}>
                    <Select
                        style={{ width: '100%' }}
                        placeholder={'Scegli una commessa'}
                        showSearch={!item}
                        open={!!item ? false : undefined}
                        onChange={(value, option) => onSelectCommission(option.commission)}
                        value={commission?.description}
                    >
                        {
                            !item && globalDataContext.allCommissions
                                .filter(comm => comm.type === 2)
                                .map((comm, idx) =>
                                    <Option
                                        key={'c' + idx}
                                        commission={comm}
                                    >
                                        {comm.description}
                                    </Option>
                                )
                        }
                    </Select>
                </Col>

                <Col span={6}>
                    <Select
                        style={{ width: '100%' }}
                        placeholder={'Scegli una sottocommessa'}
                        showSearch={!item}
                        disabled={!commission || subcommsOfCurrentComm.length === 0}
                        open={!!item ? false : undefined}
                        onChange={(value, option) => setSubcommission(option.subcommission)}
                        value={subcommission?.description}
                    >
                        {
                            !item && subcommsOfCurrentComm.map((subcomm, idx) =>
                                <Option
                                    key={'s' + idx}
                                    subcommission={subcomm}
                                    disabled={!subcomm.active}
                                >
                                    {subcomm.description}
                                </Option>
                            )
                        }
                    </Select>
                </Col>

                <Col span={3}>
                    <InputNumber
                        style={{ width: '100%' }}
                        prefix="€"
                        min={0}
                        precision={2}
                        formatter={val => (+val).toFixed(2)}
                        type={'number'}
                        readOnly={!!item}
                        onChange={setAmount}
                        value={amount}
                    />
                </Col>

                <Col span={6}>
                    <TextArea
                        style={{ width: '100%' }}
                        allowClear={!item}
                        autoSize
                        maxLength={200}
                        placeholder="Inserisci una descrizione (max 200 caratteri)"
                        showCount={!item}
                        value={description}
                        onChange={e => !!item ? () => {} : setDescription(e.target.value)}
                    />
                </Col>

                <Col span={2}>
                    <Button
                        icon={<SaveOutlined />}
                        type='primary'
                        disabled={!!item || !commission || (!subcommission && subcommsOfCurrentComm.length > 0) || amount === 0 || !description}
                        onClick={() => onSaveCost(day, commission.id, subcommission?.id || 0, amount, description)}
                    />
                    <Button
                        icon={<DeleteOutlined />}
                        type='primary'
                        danger
                        onClick={() => onDeleteCost(item?.id)}
                    />
                </Col>

            </Row>
        </List.Item>
    );
};

export default Cost;