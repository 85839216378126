import {useContext, useEffect, useState} from 'react';
import {ConfigProvider, Row, Spin, Table} from 'antd';
import dayjs, {monthsShort} from 'dayjs';
import {CategoryScale, Chart, LinearScale, LineController, LineElement, PointElement, Title} from 'chart.js';
import {Line} from 'react-chartjs-2';
import {axiosCall, INVALID_VALUE} from '../utilities';
import {GlobalDataContext} from "./App";

dayjs.locale('it');
Chart.register(LineController, LineElement, PointElement, LinearScale, Title, CategoryScale);

const DaysOffAndLeaveReport = () => {
    const globalDataContext = useContext(GlobalDataContext);

    const [loading, setLoading] = useState(false);
    const [usersdaysoffandleave, setUsersdaysoffandleave] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const response = await axiosCall('GET', '/usersdaysoffandleave', null, globalDataContext.logout);

            setUsersdaysoffandleave(response.data);
        };

        try {
            setLoading(true);

            fetchData();
        } catch (e) {
            setUsersdaysoffandleave(null);
        } finally {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const now = new Date();
    const curMonth = now.getMonth();
    const daysInMonth = new Date(now.getFullYear(), (now.getMonth() + 1) % 12, 0).getDate();
    const monthsLabels = monthsShort().slice(0, curMonth + 1).map(m => m.toUpperCase());

    const EmptyTable = () => (
        <div style={{ textAlign: 'center' }}>
            <p>Dati non presenti</p>
        </div>
    );

    return (
        <div style={{ padding: '0 20px 20px' }}>
            <Spin spinning={loading} size='large'>
                <Row justify="center">
                    <ConfigProvider renderEmpty={EmptyTable}>
                        <Table
                            size={'small'}
                            bordered
                            rowKey={data => data.name}
                            columns={[
                                {
                                    title: 'Dipendente',
                                    dataIndex: 'name'
                                },
                                {
                                    title: 'Residui anno precedente',
                                    children: [
                                        {
                                            title: 'Ferie (giorni)',
                                            dataIndex: 'lastYearDaysOff',
                                            align: 'center',
                                            render: val => val.toFixed(1)
                                        },
                                        {
                                            title: 'Permessi (ore)',
                                            dataIndex: 'lastYearLeave',
                                            align: 'center',
                                            render: val => val.toFixed(1)
                                        }
                                    ]
                                },
                                {
                                    title: 'Disponibili anno in corso',
                                    children: [
                                        {
                                            title: 'Ferie (giorni)',
                                            dataIndex: 'curDaysOff',
                                            align: 'center',
                                            render: val => val[curMonth] === INVALID_VALUE ? '-' : val[curMonth].toFixed(1)
                                        },
                                        {
                                            title: 'Permessi (ore)',
                                            dataIndex: 'curLeave',
                                            align: 'center',
                                            render: val => val[curMonth] === INVALID_VALUE ? '-' : val[curMonth].toFixed(1)
                                        }
                                    ]
                                },
                                {
                                    title: `Tot al ${daysInMonth}/${curMonth + 1} (giorni)`,
                                    dataIndex: 'totDays',
                                    align: 'center',
                                    render: val => val[curMonth] === INVALID_VALUE ? '-' : val[curMonth].toFixed(1)
                                },
                                {
                                    title: 'Andamento annuale',
                                    render: (val, record) => <Line
                                        height={110}
                                        data={{
                                            labels: monthsLabels,
                                            datasets: [{
                                                data: record.totDays.slice(0, curMonth + 1),
                                                fill: false,
                                                borderColor: 'black',
                                                borderWidth: 1
                                            }]
                                        }}
                                        options={{
                                            scales: {
                                                y: {
                                                    beginAtZero: true
                                                }
                                            },
                                            plugins: {
                                                legend: {
                                                    display: false
                                                }
                                            }
                                        }}
                                    />
                                }
                            ]}
                            dataSource={usersdaysoffandleave}
                            pagination={false}
                            rowClassName={record => record.totDays[curMonth] >= 30 ? 'daysOffAndLeaveReportTableDarkRed' : (record.totDays[curMonth] >= 20 ? 'daysOffAndLeaveReportTableRed' : (record.totDays[curMonth] < 10 ? 'daysOffAndLeaveReportTableGreen' : 'daysOffAndLeaveReportTableYellow'))}
                        />
                    </ConfigProvider>
                </Row>
            </Spin>
        </div>
    );
};

export default DaysOffAndLeaveReport;