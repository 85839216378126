import {useContext, useState} from 'react';
import {Button, Col, InputNumber, Row, Select, Spin} from 'antd';

import {
    axiosCall,
    extractDataForExport,
    extractDataForTickets,
    getCompleteName,
    getItem,
    MAX_YEAR,
    MESSAGE_TYPE_ERROR,
    MESSAGE_TYPE_SUCCESS,
    MIN_YEAR,
    MONTHS_OBJECTS,
    showToast
} from '../utilities';
import Timesheet from './Timesheet';
import {GlobalDataContext} from "./App";

const {Option} = Select;

const AllTimesheets = ({
    myTabKey = null,
    selectedTab = null
}) => {
    const globalDataContext = useContext(GlobalDataContext);

    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState(MONTHS_OBJECTS[new Date().getMonth()]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [loadingData, setLoadingData] = useState(false);

    const daysConcat = daysArray => {
        let ret = ''
        if (daysArray && Array.isArray(daysArray)) {
            daysArray.forEach(day => ret += day + ', ');
        }

        if (ret.length > 0) {
            ret = ret.substr(0, ret.length - 2);
        }

        return ret;
    };

    const buildStringDataForExport = data => {
        let stringData = `${data.completeName}\n${'-'.repeat(data.completeName.length)}\n`;

        if (data.daysOff) {
            stringData += `Ferie: ${data.daysOff} ${data.daysOff === 1 ? 'giorno' : 'giorni'} (${data.daysOffDates})\n`;
        }

        if (data.leave) {
            stringData += `Permessi: ${data.leave} ${data.leave === 1 ? 'ora' : 'ore'}\n`;
        }

        if (data.permessiGiornalieri.size) {
            data.permessiGiornalieri.forEach((value, key) => stringData += `${key}: ${value.hours} ${value.hours === 1 ? 'giorno' : 'giorni'} (In data: ${daysConcat(value.days)})\n`);
        }

        if (data.permessiAOre.size) {
            data.permessiAOre.forEach((value, key) => stringData += `${key}: ${value.hours} ${value.hours === 1 ? 'ora' : 'ore'} (In data: ${daysConcat(value.days)})\n`);
        }

        if (data.overtimeHours) {
            stringData += `Straordinari: ${data.overtimeHours} ${data.overtimeHours === 1 ? 'ora' : 'ore'}\n`;
        }

        if (data.malattia) {
            stringData += `Malattia: ${data.malattia} ${data.malattia === 1 ? 'giorno' : 'giorni'}\n`;
        }

        return stringData + '\n';
    };

    const buildStringDataForExpenses = (userId, userMap) => {
        const userName = getCompleteName(globalDataContext.allUsers, userId);
        let stringData = `${userName}\n${'-'.repeat(userName.length)}\n`;
        let sum = 0;

        userMap.forEach((value, key) => {
            const keyCommSubCommIds = key.split('§');
            const commission = getItem(globalDataContext.allCommissions, parseInt(keyCommSubCommIds[0]));

            stringData += `\n${commission.description}`;
            if (keyCommSubCommIds[1] !== '0') {
                const subcommission = getItem(globalDataContext.allSubcommissions, parseInt(keyCommSubCommIds[1]));
                stringData += ` - ${subcommission.description}:\n`;
            } else {
                stringData += ':\n'
            }

            value.forEach(exp => {
                sum += exp.amount;
                const date = (exp.date % 100).toString().padStart(2, '0') + '/' + (Math.floor((exp.date/100)) % 100).toString().padStart(2, '0') + '/'  + exp.date.toString().substring(0, 4);
                stringData += "  " + date + " " + exp.amount.toFixed(2).padStart(7, ' ') + " € " + exp.description + "\n";
            })
        });

        stringData += `\nTOTALE: ${sum.toFixed(2)} €\n\n\n\n`;

        return stringData;
    };

    const exportUsersMonthData = async () => {
        try {
            setLoadingData(true);

            let response = await axiosCall('GET', `/monthlystats/${selectedYear}/${selectedMonth.id}`, null, globalDataContext.logout);
            let data;
            if (response.data.dayByDay) {
                data = extractDataForExport(response.data, globalDataContext.allCommissions, globalDataContext.allSubcommissions, globalDataContext.allUsers, selectedYear, selectedMonth.id);
            }

            if (!data) {
                setLoadingData(false);
                showToast("Non ci sono dati per il mese e l'anno selezionati", MESSAGE_TYPE_SUCCESS, null);
                return;
            }
            let stringData = '';
            data.forEach(userData => {
                if (userData.daysOff || userData.leave || userData.permessiGiornalieri.size || userData.permessiAOre.size || userData.overtimeHours || userData.malattia) {
                    stringData += buildStringDataForExport(userData);
                }
            });

            if (!stringData) {
                setLoadingData(false);
                showToast("Non ci sono dati per il mese e l'anno selezionati", MESSAGE_TYPE_SUCCESS, null);
                return;
            }

            stringData += '\n\n◐ = Mezza giornata di ferie';

            const element = document.createElement("a");
            const file = new Blob([stringData], { type: 'text/plain' });
            element.href = URL.createObjectURL(file);
            element.download = `Ferie-permessi-straordinari dipendenti ${selectedYear} ${selectedMonth.name}.txt`;
            setLoadingData(false);
            element.click();
        } catch (e) {
            setLoadingData(false);
            showToast('Esportazione fallita', MESSAGE_TYPE_ERROR, e);
        }
    };

    const exportUsersTickets = async () => {
        try {
            setLoadingData(true);

            let response = await axiosCall('GET', `/monthlystats/${selectedYear}/${selectedMonth.id}`, null, globalDataContext.logout);
            let data;
            if (response.data.dayByDay) {
                data = extractDataForTickets(response.data, globalDataContext.allCommissions, globalDataContext.allSubcommissions, globalDataContext.allUsers, selectedYear, selectedMonth.id);
            }

            if (!data) {
                setLoadingData(false);
                showToast("Non ci sono dati per il mese e l'anno selezionati", MESSAGE_TYPE_SUCCESS, null);
                return;
            }

            let stringData = '';
            data.forEach(userData => {
                if (userData.tickets) {
                    stringData += `${userData.completeName}: ${userData.tickets}\n`;
                }
            });

            if (!stringData) {
                setLoadingData(false);
                showToast("Non ci sono dati per il mese e l'anno selezionati", MESSAGE_TYPE_SUCCESS, null);
                return;
            }

            const element = document.createElement("a");
            const file = new Blob([stringData], { type: 'text/plain' });
            element.href = URL.createObjectURL(file);
            element.download = `Spettanza ticket ${selectedYear} ${selectedMonth.name}.txt`;
            setLoadingData(false);
            element.click();
        } catch (e) {
            setLoadingData(false);
            showToast('Esportazione fallita', MESSAGE_TYPE_ERROR, e);
        }
    };

    const exportExpenses = async () => {
        try {
            setLoadingData(true);

            let response = await axiosCall('GET', `/costs/${selectedYear}/${selectedMonth.id}`, null, globalDataContext.logout);

            const data = new Map();
            const userList = [];
            let stringData = '';

            response.data.forEach(d => {
                const mapKey = d.commissionId + "§" + d.subcommissionId;
                let userMap;
                let mapValue;

                if (data.has(d.userId)) {
                    userMap = data.get(d.userId);
                    if (userMap.has(mapKey)) {
                        mapValue = userMap.get(mapKey);
                    } else {
                        mapValue = [];
                    }
                    mapValue.push({
                        date: d.date,
                        amount: d.amount,
                        description: d.description
                    });
                    userMap.set(mapKey, mapValue);
                } else {
                    userMap = new Map();
                    mapValue = [{
                        date: d.date,
                        amount: d.amount,
                        description: d.description
                    }];
                    userMap.set(mapKey, mapValue);
                    data.set(d.userId, userMap);
                    userList.push(d.userId);
                }
            });

            if (data.size === 0) {
                setLoadingData(false);
                showToast("Non ci sono spese per il mese e l'anno selezionati", MESSAGE_TYPE_SUCCESS, null);
                return;
            }

            userList.sort((a, b) => a - b).forEach(userId => stringData += buildStringDataForExpenses(userId, data.get(userId)));

            const element = document.createElement("a");
            const file = new Blob([stringData], { type: 'text/plain' });
            element.href = URL.createObjectURL(file);
            element.download = `Spese dipendenti ${selectedYear} ${selectedMonth.name}.txt`;
            setLoadingData(false);
            element.click();
        } catch (e) {
            setLoadingData(false);
            showToast('Esportazione fallita', MESSAGE_TYPE_ERROR, e);
        }
    };

    const feriePermessiButtonLabel = ['Report ferie/permessi/straordinari/malattia', <br key={'br1'} />, `${selectedMonth.name} ${selectedYear}`];
    const ticketButtonLabel = ['Spettanza ticket', <br key={'br2'} />, `${selectedMonth.name} ${selectedYear}`];
    const speseButtonLabel = ['Spese dipendenti', <br key={'br3'} />, `${selectedMonth.name} ${selectedYear}`];

    return (
        <div style={{ padding: '0 20px 20px 20px' }}>
            <Spin spinning={loadingData} size="large">
                <Row gutter={8} justify="space-between" style={{ marginBottom: 20 }}>
                    <Col span={10}>
                        <Row gutter={8}>
                            <Col span={5}>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    min={MIN_YEAR}
                                    max={MAX_YEAR}
                                    defaultValue={MAX_YEAR}
                                    type={'number'}
                                    onChange={setSelectedYear}
                                />
                            </Col>

                            <Col span={7}>
                                <Select
                                    style={{ width: '100%' }}
                                    listHeight={384}
                                    defaultValue={MONTHS_OBJECTS[new Date().getMonth()].name}
                                    onChange={(value, option) => setSelectedMonth(MONTHS_OBJECTS[option.idx])}
                                >
                                    {
                                        MONTHS_OBJECTS.map((month, idx) =>
                                            <Option
                                                key={'m' + idx} value={month.name}
                                                idx={idx}>{month.name}
                                            </Option>
                                        )
                                    }
                                </Select>
                            </Col>

                            <Col span={12}>
                                <Select
                                    style={{ width: '100%' }}
                                    defaultValue="Scegli il dipendente"
                                    onChange={(value, option) => setSelectedUser(getItem(globalDataContext.allUsers, option.userid))}
                                >
                                    {
                                        globalDataContext.allUsers.map((user, idx) =>
                                            <Option
                                                key={'u' + idx} value={user.surname + ' ' + user.name}
                                                userid={user.id}
                                            >
                                                {user.surname + ' ' + user.name}
                                            </Option>
                                        )
                                    }
                                </Select>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row gutter={8}>
                            <Col>
                                <Button
                                    style={{ height: 'auto' }}
                                    type="primary"
                                    onClick={exportUsersMonthData}
                                >
                                    {feriePermessiButtonLabel}
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    style={{ height: 'auto' }}
                                    type="primary"
                                    onClick={exportUsersTickets}
                                >
                                    {ticketButtonLabel}
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    style={{ height: 'auto' }}
                                    type="primary"
                                    onClick={exportExpenses}
                                >
                                    {speseButtonLabel}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Timesheet
                    userMaxHours={selectedUser?.workingHours}
                    selectedMonthId={selectedMonth?.id}
                    selectedYear={selectedYear}
                    selectedUserId={selectedUser?.id}
                    myTabKey={myTabKey}
                    selectedTab={selectedTab}
                    setLoadingData={setLoadingData}
                />
            </Spin>
        </div>
    );
};

export default AllTimesheets;