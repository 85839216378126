import {useContext, useState} from 'react';
import {Col, InputNumber, Row, Select, Spin} from 'antd';

import {MAX_YEAR, MIN_YEAR, MONTHS_OBJECTS} from '../utilities';
import Timesheet from './Timesheet';
import {GlobalDataContext} from './App';

const {Option} = Select;

const MyTimesheet = ({
    userMaxHours = 8,
    myTabKey = null,
    selectedTab = null
}) => {
    const globalDataContext = useContext(GlobalDataContext);

    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState(MONTHS_OBJECTS[new Date().getMonth()]);
    const [loadingActivities, setLoadingActivities] = useState(false);
    const [loadingData, setLoadingData] = useState(false);

    return (
        <div style={{ padding: '0 20px 20px 20px' }}>
            <Spin spinning={loadingActivities || loadingData} size="large">
                <Row gutter={8} style={{ marginBottom: 20 }}>
                    <Col xs={8} sm={4} md={3} lg={2}>
                        <InputNumber
                            style={{ width: '100%' }}
                            min={MIN_YEAR}
                            max={MAX_YEAR}
                            defaultValue={MAX_YEAR}
                            type={'number'}
                            onChange={setSelectedYear}
                        />
                    </Col>

                    <Col xs={16} sm={6} md={4} lg={3}>
                        <Select
                            style={{ width: '100%' }}
                            listHeight={384}
                            defaultValue={MONTHS_OBJECTS[new Date().getMonth()].name}
                            onChange={(value, option) => setSelectedMonth(MONTHS_OBJECTS[option.idx])}
                        >
                            {
                                MONTHS_OBJECTS.map((month, idx) => <Option key={'m' + idx} value={month.name} idx={idx}>{month.name}</Option>)
                            }
                        </Select>
                    </Col>
                </Row>

                <Timesheet
                    userMaxHours={userMaxHours}
                    selectedMonthId={selectedMonth.id}
                    selectedYear={selectedYear}
                    selectedUserId={globalDataContext.loggedUserId}
                    myTabKey={myTabKey}
                    selectedTab={selectedTab}
                    setLoadingActivities={setLoadingActivities}
                    setLoadingData={setLoadingData}
                    showActivities={true}
                />
            </Spin>
        </div>
    );
};

export default MyTimesheet;