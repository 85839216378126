import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Checkbox, ConfigProvider, DatePicker, Form, Input, Popconfirm, Row, Select, Spin, Table} from 'antd';
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/it_IT';

import {axiosCall, CAPABILITY_ADD_COSTS, CAPABILITY_COMPILE_TIMESHEET, MESSAGE_TYPE_ERROR, showToast} from '../utilities';
import {GlobalDataContext} from "./App";

dayjs.locale('it');

const EditableContext = React.createContext();

const EditableRow = ({ index, ...props }) => {
	const [form] = Form.useForm();

	return (
		<Form form={form} component={false}>
			<EditableContext.Provider value={form}>
				<tr {...props} />
			</EditableContext.Provider>
		</Form>
	);
};

const EditableCell = ({
	title,
	editable,
	children,
	dataIndex,
	record,
	extDataSource,
	extSetDataSource,
	extCapabilityFromId,
	extCapabilityFromDescription,
	extSendSave,
	...restProps
}) => {
	const [editing, setEditing] = useState(record && record[dataIndex] === '');

	const inputRef = useRef();

	const form = useContext(EditableContext);

	useEffect(() => {
		if (editing) {
			inputRef.current.focus();
		}
	}, [editing]);

	const toggleEdit = () => {
		setEditing(!editing);
		form.setFieldsValue({ [dataIndex]: record[dataIndex] });
	};

	const onKeyPress = (e, dataIndex) => {
		if (dataIndex !== 'surname' && dataIndex !== 'name' && dataIndex !== 'username') {
			const specialCharRegex = new RegExp("[\\d.]");
			const pressedKey = String.fromCharCode(!e.charCode ? e.which : e.charCode);
			if (!specialCharRegex.test(pressedKey)) {
				e.preventDefault();
				return false;
			}
		}
	};

	const EmptySelect = () =>
		<div style={{ textAlign: 'center' }}>
			<p>Hai aggiunto tutti i permessi</p>
		</div>;

	const save = async (e, dateString) => {
		try {
			if (dataIndex === 'capabilities' || dataIndex === 'hireDate') {
				const dataSource = extDataSource;
				let index = -1;
				for (let i = 0; i < dataSource.length; i++) {
					if (dataSource[i].key === record.key) {
						index = i;
						break;
					}
				}

				if (index === -1)
					return;

				const newUsr = dataSource.splice(index, 1)[0];
				if (dataIndex === 'capabilities')
					newUsr.capabilities = e.map(cap => typeof cap === 'number' ? extCapabilityFromId(cap)?.id : extCapabilityFromDescription(cap)?.id);
				else
					newUsr.hireDate = dateString;
				dataSource.splice(index, 0, newUsr);
				extSetDataSource(dataSource.slice());

				if (dataIndex === 'capabilities' && e.length === 0)
					throw new Error('Devi assegnare almeno 1 permesso');
			}

			const values = await form.validateFields();
			toggleEdit();
			record = {...record, ...values};

			if (record.capabilities.length !== 0) {
				const data = {
					id: record.key,
					username: record.username,
					surname: record.surname,
					name: record.name,
					hourlyCost: parseFloat(record.hourlyCost),
					hireDate: record.hireDate,
					capabilities: JSON.stringify(record.capabilities),
					lastYearDaysOff: parseFloat(record.lastYearDaysOff),
					lastYearLeave: parseFloat(record.lastYearLeave),
					active: record.active
				};

				extSendSave(data);
			}
		} catch (err) {
			console.error(err);
		}
	};

	let childNode = children;

	if (editable) {
		childNode = editing ? (
			<Form.Item
				style={{ margin: 0 }}
				name={dataIndex}
				rules={[
					{
						required: true,
						message: `${title} obbligatorio`
					}
				]}
			>
				<Input ref={inputRef} onPressEnter={save} onBlur={save} onKeyPress={e => onKeyPress(e, dataIndex)} />
			</Form.Item>
		) : (
			<div
				className='editable-cell-value-wrap'
				onClick={toggleEdit}
			>
				{children}
			</div>
		);
	} else if (childNode[1].key === 'permissionsSelect') {
		childNode = <ConfigProvider renderEmpty={EmptySelect}>
			<Select
				ref={inputRef}
				{...childNode[1].props}
				onChange={save}
			/>
			{
				childNode[1].props.value.length === 0 &&
				<div className='ant-form-item-explain' style={{color: '#ff4d4f'}}>
					<div>Devi assegnare almeno 1 permesso</div>
				</div>
			}
		</ConfigProvider>
	} else if (childNode[1].key === 'hireDatePicker') {
		childNode = <DatePicker
			ref={inputRef}
			{...childNode[1].props}
			onChange={save}
		/>
	}

	return <td {...restProps}>{childNode}</td>;
};

const ManageUsers = ({reloadUsers = null}) => {
	const globalDataContext = useContext(GlobalDataContext);

	const [savingUpdating, setSavingUpdating] = useState(false);
	const [allCapabilities, setAllCapabilities] = useState([]);
	const [dataSource, setDataSource] = useState(globalDataContext.allUsers.map(usr => ({
		key: usr.id,
		username: usr.username,
		surname: usr.surname,
		name: usr.name,
		hourlyCost: usr.hourlyCost,
		hireDate: usr.hireDate,
		capabilities: JSON.parse(usr.capabilities),
		lastYearDaysOff: usr.lastYearDaysOff,
		lastYearLeave: usr.lastYearLeave,
		active: usr.active
	})));

	useEffect(() => {
		getCapabilities();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setDataSource(globalDataContext.allUsers.filter(usr => usr.id > 0).map(usr => ({
			key: usr.id,
			username: usr.username,
			surname: usr.surname,
			name: usr.name,
			hourlyCost: usr.hourlyCost,
			hireDate: usr.hireDate,
			capabilities: JSON.parse(usr.capabilities),
			lastYearDaysOff: usr.lastYearDaysOff,
			lastYearLeave: usr.lastYearLeave,
			active: usr.active
		})));
	}, [globalDataContext.allUsers]);

	const getCapabilities = async () => {
		try {
			const response = await axiosCall('GET', '/capabilities', null, globalDataContext.logout);
			setAllCapabilities(response.data);
		} catch(e) {
			setAllCapabilities([]);
		}
	};

	const handleDelete = async id => {
		setSavingUpdating(true);

		try {
			if (id === 0) {
				setDataSource(dataSource.filter(usr => usr.key !== 0));
			} else {
				await axiosCall('DELETE', `/users/${id}`, null, globalDataContext.logout);
				reloadUsers();
			}
		} catch(e) {
			showToast('Errore cancellazione utente', MESSAGE_TYPE_ERROR, e);
		} finally {
			setSavingUpdating(false);
		}
	};

	const handleAdd = () => {
		const newData = {
			key: 0,
			username: '',
			surname: '',
			name: '',
			hourlyCost: 0,
			hireDate: '',
			capabilities: [CAPABILITY_COMPILE_TIMESHEET, CAPABILITY_ADD_COSTS],
			lastYearDaysOff: 0,
			lastYearLeave: 0,
			active: true
		};
		setDataSource([...dataSource, newData]);
	};

	const sendSave = async (row) => {
		setSavingUpdating(true);

		try {
			if (row.id === 0)
				await axiosCall('POST', `/users`, row, globalDataContext.logout);
			else
				await axiosCall('PUT', `/users/${row.id}`, row, globalDataContext.logout);
			reloadUsers();
		} catch(e) {
			showToast('Errore salvataggio utente', MESSAGE_TYPE_ERROR, e);
		} finally {
			setSavingUpdating(false);
		}
	};

	const buildUserObjectAndSendSave = (id, username, surname, name, hourlyCost, hireDate, capabilities, lastYearDaysOff, lastYearLeave, active) => {
		const data = {
			id,
			username,
			surname,
			name,
			hourlyCost: parseFloat(hourlyCost),
			hireDate,
			capabilities: JSON.stringify(capabilities),
			lastYearDaysOff: parseFloat(lastYearDaysOff),
			lastYearLeave: parseFloat(lastYearLeave),
			active
		};

		sendSave(data);
	};

	const capabilityFromId = id => {
		const cap = allCapabilities.filter(cap => cap.id === id);
		return cap.length === 1 ? cap[0] : null;
	};

	const capabilityFromDescription = descr => {
		const cap = allCapabilities.filter(cap => cap.description === descr);
		return cap.length === 1 ? cap[0] : null;
	};

	const EmptyTable = () =>
		<div style={{ textAlign: 'center' }}>
			<p>Al momento non esistono utenti</p>
		</div>;

	const components = {
		body: {
			row: EditableRow,
			cell: EditableCell
		}
	};

	const columnsDef = [
		{
			title: 'Cognome*',
			dataIndex: 'surname',
			editable: true
		},
		{
			title: 'Nome*',
			dataIndex: 'name',
			editable: true
		},
		{
			title: 'Username*',
			dataIndex: 'username',
			editable: true
		},
		{
			title: 'Costo orario*',
			dataIndex: 'hourlyCost',
			align: 'center',
			editable: true,
			render: text => text.toFixed(2)
		},
		{
			title: 'Data di assunzione',
			dataIndex: 'hireDate',
			render: (text, record) => <DatePicker
				key={'hireDatePicker'}
				locale={locale}
				style={{minWidth: 115}}
				value={record.hireDate === '' ? '' : dayjs(record.hireDate)}
			/>
		},
		{
			title: 'Permessi*',
			dataIndex: 'capabilities',
			render: (text, record) => <Select
				key={'permissionsSelect'}
				mode='multiple'
				placeholder='Seleziona i permessi'
				value={allCapabilities.length === 0 ? [] : record.capabilities.map(cap => capabilityFromId(cap).description)}
				style={{width: '100%', minWidth: 250}}
			>
				{
					allCapabilities.filter(c => !record.capabilities.includes(c.id)).map(cap =>
						<Select.Option key={cap.id} value={cap.id}>
							{cap.description}
						</Select.Option>
					)
				}
			</Select>
		},
		{
			title: 'Residuo ferie anno precedente*',
			dataIndex: 'lastYearDaysOff',
			align: 'center',
			editable: true,
			render: text => text.toFixed(1)
		},
		{
			title: 'Residuo permessi anno precedente*',
			dataIndex: 'lastYearLeave',
			align: 'center',
			editable: true,
			render: text => text.toFixed(1)
		},
		{
			title: 'Attivo',
			dataIndex: 'active',
			align: 'center',
			render: (text, record) => <Checkbox
				onChange={e => record.username !== '' && record.surname !== '' && record.name !== '' && record.capabilities.length > 0 &&
						buildUserObjectAndSendSave(record.key, record.username, record.surname, record.name, record.hourlyCost, record.hireDate, record.capabilities, record.lastYearDaysOff, record.lastYearLeave, e.target.checked)
					}
				checked={record.active}
			/>
		},
		{
			render: (text, record) =>
				dataSource.length >= 1 &&
				(
					record.username || record.surname || record.name ?
					<Popconfirm cancelText={'Annulla'} title='Confermi la cancellazione?' onConfirm={() => handleDelete(record.key)}>
						<Button danger>Cancella</Button>
					</Popconfirm> :
					<Button danger onClick={() => handleDelete(record.key)}>Cancella</Button>
				)
		}
	];

	const columns = columnsDef.map(col => {
		if (!col.editable && col.dataIndex !== 'hireDate' && col.dataIndex !== 'capabilities') {
			return col;
		}

		return {
			...col,
			onCell: record => ({
				record,
				editable: col.editable,
				dataIndex: col.dataIndex,
				title: col.title,
				extDataSource: dataSource,
				extSetDataSource: setDataSource,
				extCapabilityFromId: capabilityFromId,
				extCapabilityFromDescription: capabilityFromDescription,
				extSendSave: sendSave
			})
		};
	});

	return (
		<div style={{ padding: '0 20px 20px' }}>
			<Spin spinning={savingUpdating} size='large'>
				<Row>
					<Button
						onClick={handleAdd}
						type='primary'
						style={{ marginBottom: 16 }}
						disabled={dataSource.filter(usr => usr.key === 0).length > 0}
					>
						Aggiungi nuovo utente
					</Button>
				</Row>
				<Row>
					<ConfigProvider renderEmpty={EmptyTable}>
						<Table
							components={components}
							rowClassName={() => 'editable-row'}
							size={'small'}
							bordered
							dataSource={dataSource}
							columns={columns}
							pagination={false}
						/>
					</ConfigProvider>
				</Row>
			</Spin>
		</div>
	);
}

export default ManageUsers;