import {useContext, useEffect, useState} from 'react';
import {Col, ConfigProvider, InputNumber, Row, Select, Spin, Table} from 'antd';

import {axiosCall, COMM_TUTTE, getCompleteName, getItem, MAX_YEAR, MESI_TUTTI, MIN_YEAR, MONTHS_OBJECTS, SUBCOMM_TUTTE, USERS_TUTTI} from '../utilities';
import {GlobalDataContext} from "./App";

const {Option} = Select;

const CommissionsStats = () => {
    const allMonths = [MESI_TUTTI].concat(MONTHS_OBJECTS);

    const globalDataContext = useContext(GlobalDataContext);

    const [selectedMonth, setSelectedMonth] = useState(allMonths[0]);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedUser, setSelectedUser] = useState(USERS_TUTTI);
    const [selectedCommission, setSelectedCommission] = useState(null);
    const [selectedSubcommission, setSelectedSubcommission] = useState(SUBCOMM_TUTTE);
    const [detailCommissionsStats, setDetailCommissionsStats] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMonth, selectedYear, selectedUser, selectedCommission, selectedSubcommission]);

    const sumArrayElements = (array) => {
        if (!array || !Array.isArray(array) || array.length === 0) {
            return 0;
        }

        return array.reduce((partialSum, n) => partialSum + n, 0);
    };

    const EmptyTableNoComm = () => (
        <div style={{ textAlign: 'center' }}>
            <p>Non hai selezionato commesse</p>
        </div>
    );

    const EmptyTable = () => (
        <div style={{ textAlign: 'center' }}>
            <p>Non esistono dati corrispondenti ai parametri di ricerca</p>
        </div>
    );

    const Summary = () => {
        if (selectedUser.id === 0 && detailCommissionsStats && Array.isArray(detailCommissionsStats) && detailCommissionsStats.length > 0) {
            return <Table.Summary>
                <Table.Summary.Row className='summary'>
                    <Table.Summary.Cell key={'tsc0'}>Totale:</Table.Summary.Cell>
                    <Table.Summary.Cell key={'tsc1'} align={'center'}>{sumArrayElements(detailCommissionsStats.map(det => det.hours)).toFixed(1)}</Table.Summary.Cell>
                    <Table.Summary.Cell key={'tsc2'} align={'center'}>{sumArrayElements(detailCommissionsStats.map(det => det.cost)).toFixed(2)} €</Table.Summary.Cell>
                </Table.Summary.Row>
            </Table.Summary>;
        } else {
            return <></>;
        }
    };

    const selectCommission = (option, allCommissions) => {
        setSelectedCommission(getItem(allCommissions, option.commid));
        setSelectedSubcommission(SUBCOMM_TUTTE);
    };

    const getData = async () => {
        if (!selectedCommission)
            return;

        try {
            setLoading(true);

            const response = await axiosCall('GET', `/commissions/stats/${selectedYear}/${selectedMonth.id}/${selectedUser.id}/${selectedCommission.id}/${selectedSubcommission?.id ?? 0}`, null, globalDataContext.logout);

            setDetailCommissionsStats(response.data);
        } catch (e) {
            setDetailCommissionsStats([]);
        } finally {
            setLoading(false);
        }
    };

    const localAllCommissions = allCommissions => [COMM_TUTTE].concat(allCommissions.filter(comm => comm.type === 2));

    const localAllSubcommissions = allSubcommissions => [SUBCOMM_TUTTE].concat(allSubcommissions);

    const subcommsOfCurrentComm = (allSubcommissions, selectedCommission) => localAllSubcommissions(allSubcommissions).filter(subcomm => subcomm.id === 0 || subcomm.commissionId === selectedCommission?.id);

    const localAllUsers = allUsers => [USERS_TUTTI].concat(allUsers);

    return (
        <div style={{ padding: '0 20px' }}>
            <Spin spinning={loading} size="large">
                <Row gutter={8} style={{ marginBottom: 30 }}>

                    <Col span={2}>
                        <InputNumber
                            style={{ width: '100%' }}
                            min={MIN_YEAR}
                            max={MAX_YEAR}
                            defaultValue={MAX_YEAR}
                            type={'number'}
                            onChange={setSelectedYear}
                        />
                    </Col>

                    <Col span={3}>
                        <Select
                            style={{ width: '100%' }}
                            listHeight={416}
                            onChange={(value, option) => setSelectedMonth(allMonths[option.idx])}
                            value={selectedMonth.name}
                        >
                            {
                                allMonths.map((month, idx) =>
                                    <Option
                                        key={'m' + idx}
                                        value={month.name}
                                        idx={idx}
                                    >
                                        {month.name}
                                    </Option>
                                )
                            }
                        </Select>
                    </Col>

                    <Col span={5}>
                        <Select
                            style={{ width: '100%' }}
                            onChange={(value, option) => setSelectedUser(getItem(localAllUsers(globalDataContext.allUsers), option.userid))}
                            value={selectedUser.surname + ' ' + selectedUser.name}
                        >
                            {
                                localAllUsers(globalDataContext.allUsers).map((user, idx) =>
                                    <Option
                                        key={'u' + idx}
                                        value={user.surname + ' ' + user.name}
                                        userid={user.id}
                                    >
                                        {user.surname + ' ' + user.name}
                                    </Option>
                                )
                            }
                        </Select>
                    </Col>

                    <Col span={7}>
                        <Select
                            style={{ width: '100%' }}
                            defaultValue="Scegli una commessa"
                            onChange={(value, option) => selectCommission(option, localAllCommissions(globalDataContext.allCommissions))}
                        >
                            {
                                localAllCommissions(globalDataContext.allCommissions).map((comm, idx) =>
                                    <Option
                                        key={'c' + idx}
                                        value={comm.description}
                                        commid={comm.id}
                                    >
                                        {comm.description}
                                    </Option>
                                )
                            }
                        </Select>
                    </Col>

                    <Col span={7}>
                        <Select
                            style={{ width: '100%' }}
                            defaultValue="Scegli una sottocommessa"
                            onChange={(value, option) => setSelectedSubcommission(getItem(localAllSubcommissions(globalDataContext.allSubcommissions), option.subcommid))}
                            disabled={!selectedCommission || subcommsOfCurrentComm(globalDataContext.allSubcommissions, selectedCommission).length === 1}
                            value={selectedSubcommission?.description}
                        >
                            {
                                subcommsOfCurrentComm(globalDataContext.allSubcommissions, selectedCommission).map((subcomm, idx) =>
                                    <Option
                                        key={'s' + idx}
                                        value={subcomm.description}
                                        subcommid={subcomm.id}
                                    >
                                        {subcomm.description}
                                    </Option>
                                )
                            }
                        </Select>
                    </Col>
                </Row>

                <Row>
                    <ConfigProvider renderEmpty={selectedCommission ? EmptyTable : EmptyTableNoComm}>
                        <Table
                            size={'small'}
                            rowKey={stat => stat.userId}
                            columns={[
                                {
                                    title: 'Dipendente',
                                    render: (text, record) => getCompleteName(globalDataContext.allUsers, record.userId)
                                },
                                {
                                    title: 'Ore totali',
                                    dataIndex: 'hours',
                                    align: 'center',
                                    render: text => text.toFixed(1)
                                },
                                {
                                    title: 'Costo totale',
                                    dataIndex: 'cost',
                                    align: 'center',
                                    render: text => `${text.toFixed(2)} €`
                                }
                            ]}
                            dataSource={detailCommissionsStats}
                            pagination={false}
                            summary={() => <Summary />}
                        />
                    </ConfigProvider>
                </Row>
            </Spin>
        </div>
    );
};

export default CommissionsStats;