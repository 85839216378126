import {useContext, useEffect, useState} from 'react';
import {Button, Col, ConfigProvider, Empty, Row, Table, Tooltip} from 'antd';
import Activity from './Activity';
import {
    axiosCall,
    extractMonthData,
    getItem,
    ID_COMMISSION_FERIE,
    ID_COMMISSION_PERMESSO,
    MESSAGE_TYPE_ERROR,
    MESSAGE_TYPE_SUCCESS,
    showToast
} from '../utilities';
import {GlobalDataContext} from "./App";

const Timesheet = ({
    userMaxHours = 8,
    selectedMonthId = 0,
    selectedYear = null,
    selectedUserId = null,
    myTabKey = null,
    selectedTab = null,
    setLoadingActivities = null,
    setLoadingData = null,
    showActivities = false
}) => {
    const globalDataContext = useContext(GlobalDataContext);

    const [selectedDay, setSelectedDay] = useState(new Date().getFullYear() === selectedYear && new Date().getMonth() + 1 === selectedMonthId ? new Date().getDate() : 1);
    const [currentDateActivities, setCurrentDateActivities] = useState([]);
    const [allDailyStats, setAllDailyStats] = useState([]);
    const [monthTotals, setMonthTotals] = useState([]);
    const [yearTotals, setYearTotals] = useState([]);
    const [daysOffAndLeaveReport, setDaysOffAndLeaveReport] = useState([]);

    useEffect(() => {
        const initDate = new Date();
        setSelectedDay(initDate.getFullYear() === selectedYear && initDate.getMonth() + 1 === selectedMonthId ? initDate.getDate() : 1);
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMonthId, selectedYear]);

    useEffect(() => {
        getData();
        if (showActivities) {
            getActivitiesByDate();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUserId, showActivities]);

    useEffect(() => {
        if (selectedTab === myTabKey) {
            getData();
            if (showActivities) {
                getActivitiesByDate();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTab, myTabKey, showActivities]);

    useEffect(() => {
        if (showActivities) {
            getActivitiesByDate();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDay, showActivities]);

    const EmptyTable = () =>
        <div style={{ textAlign: 'center' }}>
            <p>Non esistono attività corrispondenti ai parametri di ricerca</p>
        </div>;

    const EmptyTableAlt = () => <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />;

    const getData = async () => {
        if (!selectedUserId)
            return;

        try {
            setLoadingData(true);

            let response = await axiosCall('GET', `/monthlystats/${selectedYear}/${selectedMonthId}/${selectedUserId}`, null, globalDataContext.logout);
            let monthData;
            if (response.data.dayByDay) {
                monthData = extractMonthData(response.data, globalDataContext.allCommissions, globalDataContext.allSubcommissions, selectedYear, selectedMonthId, selectedUserId, globalDataContext.holidays, userMaxHours);
            }

            const yearData = await axiosCall('GET', `/yeartotals/${selectedYear}/${selectedUserId}`, null, globalDataContext.logout);

            response = await axiosCall('GET', `/usersdaysoffandleave/${selectedUserId}`, null, globalDataContext.logout);

            setAllDailyStats(monthData?.allDailyStats ?? []);
            setMonthTotals([{
                monthHours: monthData?.monthHours ?? 0,
                hoursDiff: monthData ? monthData.monthHours - monthData.maxHours : 0,
                monthTickets: monthData?.monthTickets ?? 0,
                leave: monthData?.leave ?? 0,
                daysOff: monthData?.daysOff ?? 0
            }]);
            setYearTotals([{
                leave: yearData?.data?.commByComm?.filter(yt => yt.commissionId === ID_COMMISSION_PERMESSO)[0]?.hours ?? 0,
                daysOff: yearData?.data?.commByComm?.filter(yt => yt.commissionId === ID_COMMISSION_FERIE)[0]?.hours ?? 0,
                hoursDiff: yearData?.data?.hoursDiff
            }]);
            setDaysOffAndLeaveReport(response.data);
        } catch (e) {
            setAllDailyStats([]);
            setMonthTotals([]);
            setYearTotals([]);
            setDaysOffAndLeaveReport([]);
        } finally {
            setLoadingData(false);
        }
    };

    const getActivitiesByDate = async () => {
        if (!selectedUserId)
            return;

        setLoadingActivities(true);

        try {
            const response = await axiosCall('GET', `/activities/${selectedYear}${selectedMonthId.toString().padStart(2, '0')}${selectedDay.toString().padStart(2, '0')}`, null, globalDataContext.logout);

            let tmpActivities = [];
            if (response.data) {
                tmpActivities = response.data.map(act => {
                    if (act.subcommissionId === 0)
                        act.subcommissionId = undefined;
                    if (act.placeOfWorkId === 0)
                        act.placeOfWorkId = undefined;
                    return act;
                });
            }

            setCurrentDateActivities(tmpActivities);
        } catch (e) {
            setCurrentDateActivities([]);
        } finally {
            setLoadingActivities(false);
        }
    };

    const dayStyle = record => record.dailyWorkingHours > 0 || record.dailyOvertimeHours > 0 || record.permesso > 0 || record.ferie > 0 ? 'bold' : 'normal';

    const otherStyle = text => text === '✔' || text > 0 ? { fontWeight: 'bold' } : { color: 'dimgrey' };

    const renderTooltip = dailyActivities => dailyActivities.length > 0 &&
        <>
            {
                dailyActivities.map((dailyActivity, idx) => <div key={idx}>{dailyActivity}<br /></div>)
            }
        </>;

    const selectCommission = (value, option) => {
        const activityid = option.activityid;
        const commissionId = option.commissionid;

        const act = currentDateActivities.find(d => d.id === activityid);
        if (act) {
            act.commissionId = commissionId;

            const subcommsOfCurrentComm = globalDataContext.allSubcommissions.sort((a, b) => b.id - a.id).filter(subcomm => subcomm.commissionId === commissionId);
            act.subcommissionId = subcommsOfCurrentComm.length === 1 ? subcommsOfCurrentComm[0].id : undefined;
            act.placeOfWorkId = undefined;

            const comm = getItem(globalDataContext.allCommissions, commissionId);
            if (comm.type < 2) {
                act.overtimeHours = 0;
            }

            if (comm.type === 1 /*|| ((commissionId === ID_COMMISSION_REPERIBILITA_SENZA_INTERVENTO || commissionId === ID_COMMISSION_REPERIBILITA_CON_INTERVENTO) && act.hours < 1)*/) {
                act.hours = 1;
            }

            setCurrentDateActivities(currentDateActivities.slice());
        }
    };

    const selectSubcommission = (value, option) => {
        const activityid = option.activityid;
        const subcommissionId = option.subcommissionid;

        const act = currentDateActivities.find(d => d.id === activityid);
        if (act) {
            act.subcommissionId = subcommissionId;
            act.placeOfWorkId = undefined;

            setCurrentDateActivities(currentDateActivities.slice());
        }
    };

    const selectPlaceOfWork = (value, option) => {
        const activityid = option.activityid;
        const placeOfWorkId = option.placeofworkid;

        const act = currentDateActivities.find(d => d.id === activityid);
        if (act) {
            act.placeOfWorkId = placeOfWorkId;
            setCurrentDateActivities(currentDateActivities.slice());
        }
    };

    const modifyHours = (id, val) => {
        const act = currentDateActivities.find(d => d.id === id);
        if (act) {
            act.hours = val;
            setCurrentDateActivities(currentDateActivities.slice());
        }
    };

    const modifyOvertimeHours = (id, val) => {
        const act = currentDateActivities.find(d => d.id === id);
        if (act) {
            act.overtimeHours = val;
            setCurrentDateActivities(currentDateActivities.slice());
        }
    };

    const saveNotes = (id, text) => {
        const act = currentDateActivities.find(d => d.id === id);
        if (act) {
            act.notes = text;
            setCurrentDateActivities(currentDateActivities.slice());
        }
    };

    const saveActivity = async (id) => {
        try {
            const act = currentDateActivities.find(d => d.id === id);
            if (act) {
                setLoadingActivities(true);

                const activity = {
                    date: parseInt(`${selectedYear}${selectedMonthId.toString().padStart(2, '0')}${selectedDay.toString().padStart(2, '0')}`, 10),
                    hours: act.hours,
                    overtimeHours: act.overtimeHours,
                    commissionId: act.commissionId,
                    subcommissionId: act.subcommissionId,
                    placeOfWorkId: act.placeOfWorkId,
                    notes: act.notes
                };

                if (act.id === 0) {
                    const response = await axiosCall('POST', '/activities', activity, globalDataContext.logout);
                    act.id = response.data.id;
                } else {
                    await axiosCall('PUT', `/activities/${act.id}`, activity, globalDataContext.logout);
                }

                await globalDataContext.getUsersPermGraviMotiviTot();

                getData();
                setCurrentDateActivities(currentDateActivities.slice());

                const lsStringData = localStorage.getItem('lastSavedActivity');
                const lsData = lsStringData ? JSON.parse(lsStringData) : [];
                if (lsData.length > 0) {
                    const lsPos = lsData.findIndex(el => el.userId === globalDataContext.loggedUserId);
                    if (lsPos !== -1) {
                        lsData.splice(lsPos, 1);
                    }
                }
                const lsNewObj = {
                    userId: globalDataContext.loggedUserId,
                    commissionId: act.commissionId
                };
                if (act.subcommissionId !== globalDataContext.permGraviMotiviSubcommId) {
                    lsNewObj.subcommissionId = act.subcommissionId;
                }
                lsData.push(lsNewObj);
                localStorage.setItem('lastSavedActivity', JSON.stringify(lsData));

                showToast('Attività salvata', MESSAGE_TYPE_SUCCESS, null);
            } else {
                showToast('Errore: attività non trovata', MESSAGE_TYPE_ERROR, null);
            }

        } catch (e) {
            showToast('Errore salvataggio attività', MESSAGE_TYPE_ERROR, e);
        } finally {
            setLoadingActivities(false);
        }
    };

    const deleteActivity = async (id) => {
        if (id !== 0) {
            try {
                setLoadingActivities(true);

                await axiosCall('DELETE', `/activities/${id}`, null, globalDataContext.logout);

                await globalDataContext.getUsersPermGraviMotiviTot();

                getData();
            } catch (e) {
                showToast('Errore cancellazione attività', MESSAGE_TYPE_ERROR, e);
                return;
            } finally {
                setLoadingActivities(false);
            }
        }

        setCurrentDateActivities(currentDateActivities.filter(act => act.id !== id));
        showToast('Attività cancellata', MESSAGE_TYPE_SUCCESS, null);
    };

    const getRowClass = (showActivities, selectedDay, ds) => {
        let className;

        if (showActivities) {
            className = `cursor-pointer ${ds.rowClassName}`
            if (selectedDay === ds.day) {
                className += ' selectedDay';
            }
        } else {
            className = ds.rowClassName;
        }

        return className;
    };

    const now = new Date();
    const curMonth = now.getMonth();
    const daysInMonth = new Date(now.getFullYear(), (now.getMonth() + 1) % 12, 0).getDate();

    return (
        <Row gutter={32}>
            <Col xs={24} sm={24} md={14}>
                <ConfigProvider renderEmpty={EmptyTable}>
                    <Table
                        size={'small'}
                        style={{ marginBottom: window.matchMedia('(max-width: 767px)').matches ? 24 : 0 }}
                        rowKey={ds => ds.day}
                        scroll={{ y: 640 }}
                        columns={[
                            {
                                title: 'Giorno',
                                dataIndex: 'dayDow',
                                width: '8%',
                                render: (text, record) => <span style={{ fontWeight: dayStyle(record) }}>{text}</span>
                            },
                            {
                                title: 'Ore lavorate',
                                dataIndex: 'dailyWorkingHours',
                                align: 'center',
                                width: '14%',
                                render: (text, record) => <Tooltip title={renderTooltip(record.dailyActivities)}><span style={otherStyle(text)}>{text.toFixed(1)}</span></Tooltip>
                            },
                            {
                                title: 'Ore straordinario',
                                dataIndex: 'dailyOvertimeHours',
                                align: 'center',
                                width: '14%',
                                render: (text, record) => <Tooltip title={renderTooltip(record.dailyOvertimeActivities)}><span style={otherStyle(text)}>{text.toFixed(1)}</span></Tooltip>
                            },
                            {
                                title: 'Ore permesso',
                                dataIndex: 'permesso',
                                align: 'center',
                                width: '14%',
                                render: text => <span style={otherStyle(text)}>{text.toFixed(1)}</span>
                            },
                            {
                                title: 'Giorni ferie',
                                dataIndex: 'ferie',
                                align: 'center',
                                width: '14%',
                                render: text => <span style={otherStyle(text)}>{text.toFixed(1)}</span>
                            },
                            // {
                            // 	title: 'Reper. senza int.',
                            // 	dataIndex: 'repNoInt',
                            // 	align: 'center'
                            // },
                            // {
                            // 	title: 'Reper. con int.',
                            // 	dataIndex: 'repSiInt',
                            // 	align: 'center'
                            // },
                            {
                                title: 'Ticket',
                                dataIndex: 'ticket',
                                align: 'center',
                                width: '10%',
                                render: text => <span style={otherStyle(text)}>{text}</span>
                            },
                            {
                                title: 'Malattia',
                                dataIndex: 'malattia',
                                align: 'center',
                                width: '10%',
                                render: text => <span style={otherStyle(text)}>{text}</span>
                            },
                            {
                                title: 'Altri perm. (giornalieri)',
                                dataIndex: 'altriPermessiGiornalieri',
                                align: 'center',
                                width: '10%',
                                render: (text, record) => <Tooltip title={renderTooltip(record.dailyAltriPermessiGiornalieri)}><span style={otherStyle(text)}>{text}</span></Tooltip>
                            },
                            {
                                title: 'Altri perm. (a ore)',
                                dataIndex: 'altriPermessiAOre',
                                align: 'center',
                                width: '14%',
                                render: (text, record) => <Tooltip title={renderTooltip(record.dailyAltriPermessiAOre)}><span style={otherStyle(text)}>{text.toFixed(1)}</span></Tooltip>
                            }
                        ]}
                        dataSource={allDailyStats}
                        pagination={false}
                        rowClassName={ds => getRowClass(showActivities, selectedDay, ds)}
                        bordered
                        onRow={record => {
                            return {
                                onClick: () => { showActivities && setSelectedDay(record.day) }
                            };
                        }}
                    />
                </ConfigProvider>
            </Col>

            <Col xs={24} sm={24} md={10}>
                {
                    showActivities &&
                    <Row style={{ marginBottom: 16 }}>
                        <Button
                            type='primary'
                            disabled={currentDateActivities.filter(act => act.id === 0).length > 0}
                            onClick={() => setCurrentDateActivities(currentDateActivities.concat({ id: 0, hours: 0.0, overtimeHours: 0 }))}
                        >
                            Aggiungi attività
                        </Button>
                    </Row>
                }
                {
                    showActivities &&
                    currentDateActivities.map((act, idx) =>
                        <Activity
                            key={idx}
                            id={act.id}
                            hours={act.hours}
                            overtimeHours={act.overtimeHours}
                            commission={getItem(globalDataContext.allCommissions, act.commissionId)}
                            subcommission={getItem(globalDataContext.allSubcommissions, act.subcommissionId)}
                            placeOfWorkId={act.placeOfWorkId}
                            initNotes={act.notes}
                            currentDateActivities={currentDateActivities}
                            onSelectCommission={selectCommission}
                            onSelectSubcommission={selectSubcommission}
                            onSelectPlaceOfWork={selectPlaceOfWork}
                            onModifyHours={modifyHours}
                            onModifyOvertimeHours={modifyOvertimeHours}
                            onSaveNotes={saveNotes}
                            onSaveActivity={saveActivity}
                            onDeleteActivity={deleteActivity}
                        />
                    )
                }

                <Row>
                    <ConfigProvider renderEmpty={EmptyTableAlt}>
                        <Table
                            rowKey={ds => ds.monthTickets}
                            size={'small'}
                            style={{ width: '100%', marginTop: showActivities ? 48 : 0, marginBottom: 12 }}
                            columns={[
                                {
                                    title: 'Ore lavorate',
                                    dataIndex: 'monthHours',
                                    align: 'center',
                                    render: text => text.toFixed(1)
                                },
                                {
                                    title: 'Credito/debito ore',
                                    dataIndex: 'hoursDiff',
                                    align: 'center',
                                    render: text => `${text > 0 ? '+' : ''}${text.toFixed(1)}`
                                },
                                {
                                    title: 'Numero ticket spettanti',
                                    dataIndex: 'monthTickets',
                                    align: 'center'
                                },
                                {
                                    title: 'Ore di permesso godute',
                                    dataIndex: 'leave',
                                    align: 'center',
                                    render: text => text.toFixed(1)
                                },
                                {
                                    title: 'Giorni di ferie goduti',
                                    dataIndex: 'daysOff',
                                    align: 'center',
                                    render: text => <span>{text.toFixed(1)} <i>({(text * 1.2).toFixed(1)})</i></span>
                                }
                            ]}
                            dataSource={monthTotals}
                            pagination={false}
                            caption={<span style={{ color: '#1890ff', fontWeight: 600 }}>Statistiche mensili</span>}
                            bordered
                        />
                    </ConfigProvider>
                </Row>

                {
                    yearTotals.length > 0 &&
                    <Row>
                        <Table
                            rowKey={ds => ds.leave}
                            size={'small'}
                            style={{ width: '100%', marginBottom: 12 }}
                            columns={[
                                {
                                    title: 'Credito/debito ore',
                                    dataIndex: 'hoursDiff',
                                    align: 'center',
                                    render: text => `${text > 0 ? '+' : ''}${text.toFixed(1)}`
                                },
                                {
                                    title: 'Ore di permesso godute',
                                    dataIndex: 'leave',
                                    align: 'center',
                                    render: text => text.toFixed(1)
                                },
                                {
                                    title: 'Giorni di ferie goduti',
                                    dataIndex: 'daysOff',
                                    align: 'center',
                                    render: text => <span>{text.toFixed(1)} <i>({(text * 1.2).toFixed(1)})</i></span>
                                }
                            ]}
                            dataSource={yearTotals}
                            pagination={false}
                            caption={<span style={{ color: '#1890ff', fontWeight: 600 }}>Statistiche annuali</span>}
                            bordered
                        />
                    </Row>
                }

                {
                    daysOffAndLeaveReport.length > 0 &&
                    <Row>
                        <Table
                            rowKey={ds => ds.name}
                            size={'small'}
                            style={{ width: '100%' }}
                            columns={[
                                {
                                    title: 'Ore di permesso disponibili',
                                    dataIndex: 'curLeave',
                                    align: 'center',
                                    render: text => `${text[curMonth] > 0 ? '+' : ''}${text[curMonth].toFixed(1)}`
                                },
                                {
                                    title: 'Giorni di ferie disponibili',
                                    dataIndex: 'curDaysOff',
                                    align: 'center',
                                    render: text => text[curMonth].toFixed(1)
                                },
                                {
                                    title: `Tot al ${daysInMonth}/${curMonth + 1} (giorni)`,
                                    dataIndex: 'totDays',
                                    align: 'center',
                                    render: text => text[curMonth].toFixed(1)
                                }
                            ]}
                            dataSource={daysOffAndLeaveReport}
                            pagination={false}
                            caption={<span style={{ color: '#1890ff', fontWeight: 600 }}>Prospetto ferie/permessi</span>}
                            bordered
                            rowClassName={record => record.totDays[curMonth] >= 30 ? 'timesheetDaysOffAndLeaveReportTableDarkRed' : (record.totDays[curMonth] >= 20 ? 'timesheetDaysOffAndLeaveReportTableRed' : (record.totDays[curMonth] < 10 ? 'timesheetDaysOffAndLeaveReportTableGreen' : 'timesheetDaysOffAndLeaveReportTableYellow'))}
                        />
                    </Row>
                }
            </Col>
        </Row>
    );
};

export default Timesheet;