import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Checkbox, Col, ConfigProvider, Form, Input, Popconfirm, Row, Spin, Table} from 'antd';

import {axiosCall, MESSAGE_TYPE_ERROR, showToast} from '../utilities';
import {GlobalDataContext} from "./App";

const EditableCommissionContext = React.createContext();
const EditableSubcommissionContext = React.createContext();

const EditableCommissionRow = ({ index, ...props }) => {
	const [form] = Form.useForm();

	return (
		<Form form={form} component={false}>
			<EditableCommissionContext.Provider value={form}>
				<tr {...props} />
			</EditableCommissionContext.Provider>
		</Form>
	);
};

const EditableSubcommissionRow = ({ index, ...props }) => {
	const [form] = Form.useForm();

	return (
		<Form form={form} component={false}>
			<EditableSubcommissionContext.Provider value={form}>
				<tr {...props} />
			</EditableSubcommissionContext.Provider>
		</Form>
	);
};

const EditableCommissionCell = ({
	title,
	editable,
	children,
	dataIndex,
	record,
	handleDescriptionChanged,
	...restProps
}) => {
	const [editing, setEditing] = useState(record && record.description === '');

	const inputRef = useRef();

	const form = useContext(EditableCommissionContext);

	useEffect(() => {
		if (editing) {
			inputRef.current.focus();
		}
	}, [editing]);

	const toggleEdit = () => {
		setEditing(!editing);
		form.setFieldsValue({ [dataIndex]: record[dataIndex] });
	};

	const save = async () => {
		try {
			const values = await form.validateFields();
			toggleEdit();
			handleDescriptionChanged({ ...record, ...values });
		} catch (err) {
			console.error(err);
		}
	};

	let childNode = children;

	if (editable) {
		childNode = editing ? (
			<Form.Item
				style={{ margin: 0 }}
				name={dataIndex}
				rules={[
					{
						required: true,
						message: `${title} obbligatoria`
					}
				]}
			>
				<Input ref={inputRef} onPressEnter={save} onBlur={save} />
			</Form.Item>
		) : (
			<div
				className='editable-cell-value-wrap'
				onClick={toggleEdit}
			>
				{children}
			</div>
		);
	}

	return <td {...restProps}>{childNode}</td>;
};

const EditableSubcommissionCell = ({
	title,
	editable,
	children,
	dataIndex,
	record,
	handleDescriptionChanged,
	...restProps
}) => {
	const [editing, setEditing] = useState(record && record.description === '');

	const inputRef = useRef();

	const form = useContext(EditableSubcommissionContext);

	useEffect(() => {
		if (editing) {
			inputRef.current.focus();
		}
	}, [editing]);

	const toggleEdit = () => {
		setEditing(!editing);
		form.setFieldsValue({ [dataIndex]: record[dataIndex] });
	};

	const save = async () => {
		try {
			const values = await form.validateFields();
			toggleEdit();
			handleDescriptionChanged({ ...record, ...values });
		} catch (err) {
			console.error(err);
		}
	};

	let childNode = children;

	if (editable) {
		childNode = editing ? (
			<Form.Item
				style={{ margin: 0 }}
				name={dataIndex}
				rules={[
					{
						required: true,
						message: `${title} obbligatoria`
					}
				]}
			>
				<Input ref={inputRef} onPressEnter={save} onBlur={save} />
			</Form.Item>
		) : (
			<div
				className='editable-cell-value-wrap'
				onClick={toggleEdit}
			>
				{children}
			</div>
		);
	}

	return <td {...restProps}>{childNode}</td>;
};

const ManageCommissions = ({reloadCommissions = null, reloadSubcommissions = null}) => {
	const globalDataContext = useContext(GlobalDataContext);

	const [savingUpdating, setSavingUpdating] = useState(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [commissionsList, setCommissionsList] = useState(globalDataContext.allCommissions.filter(comm => comm.type === 2).map(comm => ({
		key: comm.id,
		description: comm.description,
		active: comm.active
	})));
	const [subcommissionsList, setSubcommissionsList] = useState(globalDataContext.allSubcommissions.map(subcomm => ({
		key: subcomm.id,
		commissionId: subcomm.commissionId,
		description: subcomm.description,
		active: subcomm.active
	})));

	useEffect(() => {
		setCommissionsList(globalDataContext.allCommissions.filter(comm => comm.type === 2).map(comm => ({
			key: comm.id,
			description: comm.description,
			active: comm.active
		})));
	}, [globalDataContext.allCommissions]);

	useEffect(() => {
		setSubcommissionsList(globalDataContext.allSubcommissions.map(subcomm => ({
			key: subcomm.id,
			commissionId: subcomm.commissionId,
			description: subcomm.description,
			active: subcomm.active
		})));
	}, [globalDataContext.allSubcommissions]);

	const commissionsColumns = [
		{
			title: 'Descrizione*',
			dataIndex: 'description',
			editable: true
		},
		{
			title: 'Attiva',
			dataIndex: 'active',
			align: 'center',
			render: (text, record) => <Checkbox
				onChange={e => record.description !== '' &&
					(
						record.key === 0 ?
							sendCommissionSave({
								description: record.description,
								active: e.target.checked
							}) :
							sendCommissionUpdate({
								id: record.key,
								description: record.description,
								active: e.target.checked
							})
					)}
				checked={record.active}
			/>
		},
		{
			render: (text, record) =>
				commissionsList.length >= 1 &&
				(
					record.description ?
						<Popconfirm cancelText={'Annulla'} title='Confermi la cancellazione?' onConfirm={() => handleCommissionDelete(record.key)}>
							<Button danger>Cancella</Button>
						</Popconfirm> :
						<Button danger onClick={() => handleCommissionDelete(record.key)}>Cancella</Button>
				)
		}
	];

	const subcommissionsColumns = [
		{
			title: 'Descrizione*',
			dataIndex: 'description',
			editable: true
		},
		{
			title: 'Attiva',
			dataIndex: 'active',
			render: (text, record) => <Checkbox
				onChange={e => record.description !== '' &&
					(
						record.key === 0 ?
							sendSubcommissionSave({
								description: record.description,
								active: e.target.checked.toString()
							}) :
							sendSubcommissionUpdate({
								id: record.key,
								description: record.description,
								active: e.target.checked
							})
					)}
				checked={record.active}
			/>
		},
		{
			render: (text, record) =>
				subcommissionsList.length >= 1 &&
				(
					record.description ?
						<Popconfirm cancelText={'Annulla'} title='Confermi la cancellazione?' onConfirm={() => handleSubcommissionDelete(record.key)}>
							<Button danger>Cancella</Button>
						</Popconfirm> :
						<Button danger onClick={() => handleSubcommissionDelete(record.key)}>Cancella</Button>
				)
		}
	];

	const handleCommissionDelete = async id => {
		setSavingUpdating(true);

		try {
			if (id === 0) {
				setCommissionsList(commissionsList.filter(comm => comm.key !== 0));
			} else {
				await axiosCall('DELETE', `/commissions/${id}`, null, globalDataContext.logout);
				reloadCommissions();
			}
		} catch(e) {
			showToast('Errore cancellazione commessa', MESSAGE_TYPE_ERROR, e);
		} finally {
			setSavingUpdating(false);
		}
	};

	const handleSubcommissionDelete = async id => {
		setSavingUpdating(true);


		try {
			if (id === 0) {
				setSubcommissionsList(subcommissionsList.filter(subcomm => subcomm.key !== 0));
			} else {
				await axiosCall('DELETE', `/subcommissions/${id}`, null, globalDataContext.logout);
				reloadSubcommissions();
			}
		} catch(e) {
			showToast('Errore cancellazione sottocommessa', MESSAGE_TYPE_ERROR, e);
		} finally {
			setSavingUpdating(false);
		}
	};

	const handleCommissionAdd = () => {
		const newData = {
			key: 0,
			description: '',
			active: true
		};
		setCommissionsList([...commissionsList, newData]);
	};

	const handleSubcommissionAdd = () => {
		const newData = {
			key: 0,
			commissionId: selectedRowKeys[0],
			description: '',
			active: true
		};
		setSubcommissionsList([...subcommissionsList, newData]);
	};

	const handleCommissionDescriptionChanged = row => {
		if (row.key === 0)
			sendCommissionSave(row);
		else if (globalDataContext.allCommissions.filter(comm => comm.id === row.key)[0].description !== row.description)
			sendCommissionUpdate({id: row.key, description: row.description, active: row.active});
	};

	const handleSubcommissionDescriptionChanged = row => {
		if (row.key === 0)
			sendSubcommissionSave(row);
		else if (globalDataContext.allSubcommissions.filter(subcomm => subcomm.id === row.key)[0].description !== row.description)
			sendSubcommissionUpdate({id: row.key, commissionId: row.commissionId, description: row.description, active: row.active});
	};

	const sendCommissionUpdate = async row => {
		setSavingUpdating(true);

		try {
			await axiosCall('PUT', `/commissions/${row.id}`, row, globalDataContext.logout);
			reloadCommissions();
		} catch(e) {
			showToast('Errore salvataggio modifche', MESSAGE_TYPE_ERROR, e);
		} finally {
			setSavingUpdating(false);
		}
	};

	const sendSubcommissionUpdate = async row => {
		setSavingUpdating(true);

		try {
			await axiosCall('PUT', `/subcommissions/${row.key}`, row, globalDataContext.logout);
			reloadSubcommissions();
		} catch(e) {
			showToast('Errore salvataggio modifche', MESSAGE_TYPE_ERROR, e);
		} finally {
			setSavingUpdating(false);
		}
	};

	const sendCommissionSave = async row => {
		setSavingUpdating(true);

		try {
			await axiosCall('POST', `/commissions`, row, globalDataContext.logout);
			reloadCommissions();
		} catch(e) {
			showToast('Errore salvataggio modifche', MESSAGE_TYPE_ERROR, e);
		} finally {
			setSavingUpdating(false);
		}
	};

	const sendSubcommissionSave = async row => {
		setSavingUpdating(true);

		try {
			await axiosCall('POST', `/subcommissions`, row, globalDataContext.logout);
			reloadSubcommissions();
		} catch(e) {
			showToast('Errore salvataggio modifche', MESSAGE_TYPE_ERROR, e);
		} finally {
			setSavingUpdating(false);
		}
	};

	const EmptyCommissionsTable = () =>
		<div style={{ textAlign: 'center' }}>
			<p>Al momento non esistono commesse salvate</p>
		</div>;

	const EmptySubcommissionsTable = () =>
		<div style={{ textAlign: 'center' }}>
			<p>Al momento non esistono sottocommesse salvate</p>
		</div>;

	const commissionComponents = {
		body: {
			row: EditableCommissionRow,
			cell: EditableCommissionCell
		}
	};

	const subcommissionComponents = {
		body: {
			row: EditableSubcommissionRow,
			cell: EditableSubcommissionCell
		}
	};

	const commissionsColumnsMod = commissionsColumns.map(col => {
		if (!col.editable) {
			return col;
		}

		return {
			...col,
			onCell: record => ({
				record,
				editable: col.editable,
				dataIndex: col.dataIndex,
				title: col.title,
				handleDescriptionChanged: handleCommissionDescriptionChanged
			})
		};
	});

	const subcommissionsColumnsMod = subcommissionsColumns.map(col => {
		if (!col.editable) {
			return col;
		}

		return {
			...col,
			onCell: record => ({
				record,
				editable: col.editable,
				dataIndex: col.dataIndex,
				title: col.title,
				handleDescriptionChanged: handleSubcommissionDescriptionChanged
			})
		};
	});

	const rowSelection = {
		selectedRowKeys,
		onSelect: (record, selected) => setSelectedRowKeys(selected ? [record.key] : [])
	};

	return (
		<div style={{ padding: '0 20px 20px' }}>
			<Spin spinning={savingUpdating} size='large'>
				<Row gutter={32}>
					<Col>
						<Row>
							<Button
								onClick={handleCommissionAdd}
								type='primary'
								style={{ marginBottom: 16 }}
								disabled={commissionsList.filter(comm => comm.key === 0).length > 0}
							>
								Aggiungi nuova commessa
							</Button>
						</Row>
						<Row>
							<ConfigProvider renderEmpty={EmptyCommissionsTable}>
								<Table
									caption={<b>Commesse</b>}
									components={commissionComponents}
									rowClassName={() => 'editable-row'}
									size={'small'}
									bordered
									dataSource={commissionsList}
									columns={commissionsColumnsMod}
									pagination={false}
									rowSelection={rowSelection}
								/>
							</ConfigProvider>
						</Row>
					</Col>
					{
						selectedRowKeys.length > 0 &&
						<Col>
							<Row>
								<Button
									onClick={handleSubcommissionAdd}
									style={{ marginBottom: 16 }}
									disabled={subcommissionsList.filter(comm => comm.key === 0).length > 0}
								>
									Aggiungi nuova sottocommessa
								</Button>
							</Row>
							<Row>
								<ConfigProvider renderEmpty={EmptySubcommissionsTable}>
									<Table
										caption={<b>Sottocommesse</b>}
										components={subcommissionComponents}
										rowClassName={() => 'editable-row'}
										size={'small'}
										bordered
										dataSource={subcommissionsList.filter(subcomm => subcomm.commissionId === selectedRowKeys[0])}
										columns={subcommissionsColumnsMod}
										pagination={false}
									/>
								</ConfigProvider>
							</Row>
						</Col>
					}
				</Row>
			</Spin>
		</div>
	);
}

export default ManageCommissions;